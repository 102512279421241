@font-face {
	font-family: 'Gilroy-UltraLight';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Gilroy-UltraLight.eot');
	src: url('assets/fonts/Gilroy-UltraLight.woff?#iefix') format('embedded-opentype'),
		url('assets/fonts/Gilroy-UltraLight.ttf') format('truetype'),
		url('assets/fonts/Gilroy-UltraLight.svg?#Gilroy-UltraLight') format('esvg');
}

@font-face {
	font-family: 'Gilroy-SemiBold';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Gilroy-SemiBold.eot');
	src: url('assets/fonts/Gilroy-SemiBold.woff?#iefix') format('embedded-opentype'),
		url('assets/fonts/Gilroy-SemiBold.ttf') format('truetype'),
		url('assets/fonts/Gilroy-SemiBold.svg?#Gilroy-SemiBold') format('esvg');
}

@font-face {
	font-family: 'Gilroy-SemiBoldItalic';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Gilroy-SemiBoldItalic.eot');
	src: url('assets/fonts/Gilroy-SemiBoldItalic.woff?#iefix') format('embedded-opentype'),
		url('assets/fonts/Gilroy-SemiBoldItalic.ttf') format('truetype'),
		url('assets/fonts/Gilroy-SemiBoldItalic.svg?#Gilroy-SemiBoldItalic') format('esvg');
}

@font-face {
	font-family: 'Gilroy-Thin';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Gilroy-Thin.eot');
	src: url('assets/fonts/Gilroy-Thin.woff?#iefix') format('embedded-opentype'),
		url('assets/fonts/Gilroy-Thin.ttf') format('truetype'),
		url('assets/fonts/Gilroy-Thin.svg?#Gilroy-Thin') format('esvg');
}

@font-face {
	font-family: 'Gilroy-ThinItalic';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Gilroy-ThinItalic.eot');
	src: url('assets/fonts/Gilroy-ThinItalic.woff?#iefix') format('embedded-opentype'),
		url('assets/fonts/Gilroy-ThinItalic.ttf') format('truetype'),
		url('assets/fonts/Gilroy-ThinItalic.svg?#Gilroy-ThinItalic') format('esvg');
}

@font-face {
	font-family: 'Gilroy-HeavyItalic';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Gilroy-HeavyItalic.eot');
	src: url('assets/fonts/Gilroy-HeavyItalic.woff?#iefix') format('embedded-opentype'),
		url('assets/fonts/Gilroy-HeavyItalic.ttf') format('truetype'),
		url('assets/fonts/Gilroy-HeavyItalic.svg?#Gilroy-HeavyItalic') format('esvg');
}

@font-face {
	font-family: 'Gilroy-Light';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Gilroy-Light.eot');
	src: url('assets/fonts/Gilroy-Light.woff?#iefix') format('embedded-opentype'),
		url('assets/fonts/Gilroy-Light.ttf') format('truetype'),
		url('assets/fonts/Gilroy-Light.svg?#Gilroy-Light') format('esvg');
}

@font-face {
	font-family: 'Gilroy-MediumItalic';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Gilroy-MediumItalic.eot');
	src: url('assets/fonts/Gilroy-MediumItalic.woff?#iefix') format('embedded-opentype'),
		url('assets/fonts/Gilroy-MediumItalic.ttf') format('truetype'),
		url('assets/fonts/Gilroy-MediumItalic.svg?#Gilroy-MediumItalic') format('esvg');
}

@font-face {
	font-family: 'Gilroy-Regular';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Gilroy-Regular.eot');
	src: url('assets/fonts/Gilroy-Regular.woff?#iefix') format('embedded-opentype'),
		url('assets/fonts/Gilroy-Regular.ttf') format('truetype'),
		url('assets/fonts/Gilroy-Regular.svg?#Gilroy-Regular') format('esvg');
}

@font-face {
	font-family: 'Gilroy-RegularItalic';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Gilroy-RegularItalic.eot');
	src: url('assets/fonts/Gilroy-RegularItalic.woff?#iefix') format('embedded-opentype'),
		url('assets/fonts/Gilroy-RegularItalic.ttf') format('truetype'),
		url('assets/fonts/Gilroy-RegularItalic.svg?#Gilroy-RegularItalic') format('esvg');
}

@font-face {
	font-family: 'Gilroy-ExtraBoldItalic';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Gilroy-ExtraBoldItalic.eot');
	src: url('assets/fonts/Gilroy-ExtraBoldItalic.woff?#iefix') format('embedded-opentype'),
		url('assets/fonts/Gilroy-ExtraBoldItalic.ttf') format('truetype'),
		url('assets/fonts/Gilroy-ExtraBoldItalic.svg?#Gilroy-ExtraBoldItalic') format('esvg');
}

@font-face {
	font-family: 'Gilroy-Heavy';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Gilroy-Heavy.eot');
	src: url('assets/fonts/Gilroy-Heavy.woff?#iefix') format('embedded-opentype'),
		url('assets/fonts/Gilroy-Heavy.ttf') format('truetype'),
		url('assets/fonts/Gilroy-Heavy.svg?#Gilroy-Heavy') format('esvg');
}

@font-face {
	font-family: 'Gilroy-LightItalic';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Gilroy-LightItalic.eot');
	src: url('assets/fonts/Gilroy-LightItalic.woff?#iefix') format('embedded-opentype'),
		url('assets/fonts/Gilroy-LightItalic.ttf') format('truetype'),
		url('assets/fonts/Gilroy-LightItalic.svg?#Gilroy-LightItalic') format('esvg');
}

@font-face {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Gilroy-Medium.eot');
	src: url('assets/fonts/Gilroy-Medium.woff?#iefix') format('embedded-opentype'),
		url('assets/fonts/Gilroy-Medium.ttf') format('truetype'),
		url('assets/fonts/Gilroy-Medium.svg?#Gilroy-Medium') format('esvg');
}

@font-face {
	font-family: 'Gilroy-BlackItalic';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Gilroy-BlackItalic.eot');
	src: url('assets/fonts/Gilroy-BlackItalic.woff?#iefix') format('embedded-opentype'),
		url('assets/fonts/Gilroy-BlackItalic.ttf') format('truetype'),
		url('assets/fonts/Gilroy-BlackItalic.svg?#Gilroy-BlackItalic') format('esvg');
}

@font-face {
	font-family: 'Gilroy-BoldItalic';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Gilroy-BoldItalic.eot');
	src: url('assets/fonts/Gilroy-BoldItalic.woff?#iefix') format('embedded-opentype'),
		url('assets/fonts/Gilroy-BoldItalic.ttf') format('truetype'),
		url('assets/fonts/Gilroy-BoldItalic.svg?#Gilroy-BoldItalic') format('esvg');
}

@font-face {
	font-family: 'Gilroy-ExtraBold';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Gilroy-ExtraBold.eot');
	src: url('assets/fonts/Gilroy-ExtraBold.woff?#iefix') format('embedded-opentype'),
		url('assets/fonts/Gilroy-ExtraBold.ttf') format('truetype'),
		url('assets/fonts/Gilroy-ExtraBold.svg?#Gilroy-ExtraBold') format('esvg');
}

@font-face {
	font-family: 'Gilroy-Black';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Gilroy-Black.eot');
	src: url('assets/fonts/Gilroy-Black.woff?#iefix') format('embedded-opentype'),
		url('assets/fonts/Gilroy-Black.ttf') format('truetype'),
		url('assets/fonts/Gilroy-Black.svg?#Gilroy-Black') format('esvg');
}

@font-face {
	font-family: 'Gilroy-Bold';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Gilroy-Bold.eot');
	src: url('assets/fonts/Gilroy-Bold.woff?#iefix') format('embedded-opentype'),
		url('assets/fonts/Gilroy-Bold.ttf') format('truetype'),
		url('assets/fonts/Gilroy-Bold.svg?#Gilroy-Bold') format('esvg');
}

@font-face {
	font-family: 'Anton-Regular';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Anton-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Benzin-Semibold';
	font-style: normal;
	font-weight: normal;
	src: url('assets/fonts/Benzin-Semibold.ttf') format('truetype');
}
