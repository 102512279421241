body {
	background-color: $grey_primary;
	font-size: 16px;
	font-family: 'Gilroy-Medium';
	-webkit-font-smoothing: antialiased;

	&.menu-open {
		overflow: hidden;
	}
}

.section_wrapper {
	margin-bottom: 60px;
}

.container {
	@media (min-width: 1300px) {
		max-width: 100%;
	}

	@media (min-width: 1440px) {
		max-width: 1400px;
	}

	@media (min-width: 1500px) {
		max-width: 1400px;
	}

	@media (min-width: 1550px) {
		max-width: 1400px;
	}

	@media (min-width: 1600px) {
		max-width: 1450px;
	}

	@media (min-width: 1750px) {
		max-width: 1500px;
	}

	@media (min-width: 1800px) {
		max-width: 1500px;
	}

	@media (max-width: 1299px) {
		max-width: 100%;
		width: 100%;
	}

	&.narrow {
		max-width: 1200px;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

strong,
b {
	font-weight: normal;
	font-family: $gilroy_semiBold;
}

::selection {
	background-color: $blue_secondary;
	color: $white_primary;
}

.body {
	font-size: 14px;
	font-family: $gilroy_medium;
	color: $black_primary;
	line-height: 20px;

	&.semibold {
		font-family: $gilroy_medium;
	}

	&.large {
		font-size: 16px;
		line-height: 22px;
	}

	&.white {
		color: $white_primary;
	}

	p {
		margin-bottom: 20px;
		&:last-child {
			margin: 0;
		}
	}
}

.pill {
	position: absolute;
	right: 0;
	top: 0;
	display: inline-block;
	padding: 12px 25px;
	font-family: $gilroy_semiBold;
	background-color: $black_primary;
	font-size: 14px;
	color: $white_primary;
	border-bottom-left-radius: 26px;
	border-top-right-radius: 26px;
	&.white {
		background-color: $white_primary;
		color: $black_primary;
	}
	&.red {
		background-color: $red_primary;
		color: $white_primary;
	}
	&.grey {
		background-color: $grey_lite_2;
		color: $black_primary;
	}
	&.green {
		background-color: $green_primary;
		color: $white_primary;
	}
	&.blue-gradient {
		background: rgb(52, 0, 255);
		background: -moz-linear-gradient(275deg, $blue_secondary 0%, $blue_primary 100%);
		background: -webkit-linear-gradient(275deg, $blue_secondary 0%, $blue_primary 100%);
		background: linear-gradient(275deg, $blue_secondary 0%, $blue_primary 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3400ff",endColorstr="#6cfcfd",GradientType=1);
		color: $white_primary;
	}

	&.light_blue {
		background-color: rgba($blue_primary, 0.3);
		background-color: #d2feff;
		color: $blue_secondary;
	}

	&.normal {
		position: relative;
		right: auto;
		left: auto;
		border-radius: 20px;
		font-size: 12px;
		padding: 10px 25px;
	}
	&.confidence {
		padding: 10px 20px;
		> * {
			display: inline-block !important;
			font-size: 13px;
			font-family: $gilroy_semiBold;
			&.number {
				margin-left: 5px;
			}
		}
		&.green {
			background-color: $green_primary;
			color: $white_primary;
		}
		&.yellow {
			background-color: $yellow_primary;
		}
	}

	&.small {
		position: relative;
		right: auto;
		left: auto;
		border-radius: 10px;
		font-size: 11px !important;
		padding: 4px 8px;
		padding-left: 6px;
		//margin-left: 4px;
		font-family: $gilroy_bold !important;
		margin-right: 4px;
	}
}

.badge {
	display: inline-block;
	vertical-align: middle;
	padding: 9px 15px;
	font-family: $gilroy_semiBold;
	font-size: 14px;
	border-radius: $border_radius;
	white-space: normal;

	.svg_wrapper {
		display: inline-block;
		width: 15px;
		margin-right: 4px;
		position: relative;
		top: -2px;
		svg {
			height: auto;
			width: 100%;
		}
	}
	span,
	.svg_wrapper {
		display: inline-block;
		vertical-align: middle;
	}
	&.white {
		background-color: $white_primary;

		color: $black_primary;
	}
	&.light_blue {
		background-color: rgba($blue_primary, 0.3);
		background-color: #d2feff;
		color: $blue_secondary;
	}
	&.white_outline {
		border: 1px solid $white_primary;
		color: $white_primary;
		cursor: pointer;
		&:hover {
			background-color: $white_primary;
			color: $black_primary;
		}
	}
}

.panel {
	position: relative;
	background-color: $white_primary;
	border-radius: $border_radius;
	color: $black_primary;
	&.black {
		background-color: $black_primary;
		color: $white_primary;
	}
	&.grey {
		background-color: $grey_lite;
	}
	&.shadow {
		box-shadow: 5px 20px 30px rgba($color: $black_primary, $alpha: 0.16);
	}

	&.with_inner_content {
		padding: 32px 22px;
		@media (max-width: 575px) {
			padding: 25px 5px;
			padding-bottom: 15px;
		}
		h2 {
			@media (max-width: 767px) {
				padding-left: 20px;
			}

			@media (max-width: 575px) {
				padding-left: 25px;
			}
		}

		.view_links {
			@media (max-width: 767px) {
				padding-top: 25px;
				text-align: center !important;
			}
			@media (max-width: 575px) {
				//padding-top: 10px;
			}
		}
	}
}

.page_title {
	font-size: 36px;
	font-family: $gilroy_bold;
	color: $black_primary;
	&.white {
		color: $white_primary;
	}
	@media (max-width: 1199px) {
		font-size: 40px;
	}
	@media (max-width: 991px) {
		font-size: 36px;
	}
	@media (max-width: 767px) {
		font-size: 32px;
	}
	@media (max-width: 575px) {
		font-size: 30px;
	}
}

.heading {
	font-size: 22px;
	font-family: $gilroy_bold;
	color: $black_primary;
	&.white {
		color: $white_primary;
	}
	@media (max-width: 767px) {
		font-size: 20px;
	}
	@media (max-width: 575px) {
		font-size: 20px;
	}
}

.table {
	margin: 0;
	width: 100%;
	tbody {
		tr {
			th,
			td {
				position: relative;
				border: none;
				vertical-align: middle;
				color: $black_primary;
				font-size: 14px;
				font-family: $gilroy_semiBold;

				&.td_name {
					padding-left: 0;
					padding-right: 5px;
				}

				h1.index_no {
					display: inline-block;
					font-size: 48px;
					line-height: 50px;
					font-family: $gilroy_semiBold;
					padding-left: 50px;
					&.loading-text {
						height: 50px;
						width: 80px;
						margin-top: 5px;
						opacity: 0.6 !important;
						transform: unset !important;
					}
				}
				.name_wrap {
					display: inline-block;
					padding-left: 20px;
				}
				.jockey-img {
					width: 40px;
					height: auto;
					left: 5px;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
				}
				.player_name {
					font-size: 20px;
					font-family: $gilroy_bold;
					display: flex;
					align-items: center;
					position: relative;

					small {
						font-size: 15px;
					}

					h3 {
						font-size: 20px;
						display: inline-block;
					}

					.circle {
						width: 20px;
						height: 20px;
						border-radius: 100%;
						display: inline-block;
						color: $black_primary;
						background-color: $grey_lite_2;
						position: relative;
						font-family: $gilroy_bold;
						margin-left: 4px;
						margin-bottom: -4px;
						cursor: pointer;
						span {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							font-size: 11px;
							margin-top: 1px;
							line-height: 20px;
						}

						@media (max-width: 575px) {
							width: 18px;
							height: 18px;
							span {
								font-size: 9px;
								line-height: 10px;
								margin-top: 0;
							}
						}
						&.yard_pick {
							background-color: $blue_secondary;
							color: $white_primary;

							&.win {
								background-color: $blue_secondary;
							}

							&.against {
								background-color: $pink_secondary;
							}
						}
						&.preview {
							background-color: $blue_primary;
						}
						&.preview_place {
							background-color: $blue_primary;
						}
						&.dual_nomination {
							background-color: $black_primary;
							color: $white_primary;
						}
					}

					.image_circle {
						width: 18px;
						height: 18px;
						border-radius: 100%;
						display: inline-block;
						color: $black_primary;
						position: relative;
						font-family: $gilroy_bold;
						margin-left: 4px;
						margin-bottom: -2px;
						cursor: pointer;
						span {
							position: absolute;
							font-size: 10px;
							line-height: 10px;
							margin-top: 1px;
						}
					}
				}
				span {
					font-size: 14px;
					font-family: $gilroy_semiBold;
					display: block;

					&.player_attributes {
						font-size: 12px;
						padding-top: 3px;
						&.loading-text {
							height: 50px;
							width: 80%;
						}
					}
					&.rating {
						font-size: 18px;
						position: relative;
						display: inline-block;
						&.active {
							padding-right: 20px;
							margin-bottom: -2px;
							&:after {
								content: '';
								background-size: contain;
								background-position: center center;
								background-repeat: no-repeat;
								background-image: url('/assets/images/icons/star-black.png');
								width: 16px;
								height: 16px;
								position: absolute;
								right: 0;
								top: calc(50% - 8px);
							}
						}
					}

					&.bookmaker-img {
						padding-left: 55px;
					}
				}
				.bookmaker {
					//background-color: $blue_dark;
					width: 44px;
					//height: 44px;
					border-radius: 10px;
					position: absolute;
					left: 0.75rem;
					top: calc(50% - 22px);
				}
				strong {
					font-family: $gilroy_bold;
					//font-size: 13px;
				}
				input[type='checkbox'] {
					position: relative;
					top: 5px;
					margin-left: 15px;
					width: 20px;
					height: 20px;
				}

				.trc_rating {
					font-family: $gilroy_bold;
					font-size: 13px;
				}
			}
			&.Scratched {
				opacity: 0.4;
				th,
				td {
					text-decoration: line-through !important;
				}
			}
		}
	}
	.small_space {
		padding-bottom: 3px;
		padding-top: 1px;
		&.loading-text {
			height: 50px;
		}
	}
}

.lock-icon {
	position: relative;
	background-color: $black_primary;
	display: inline-block;
	border-radius: 100%;
	width: 24px;
	height: 24px;
	text-align: center;
	svg,
	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #ffffff;
		width: 9px;
	}
	svg {
	}
}

.bg_image {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	left: 0;
	background-size: cover;
}

.toggleBtn {
	position: relative;
	display: block;
	height: 40px;
	width: 40px;
	background-color: $black_primary;
	border-radius: 100%;
	cursor: pointer;
	@include doFadeTrans();

	@media (max-width: 768px) {
		height: 30px;
		width: 30px;
	}

	&:after {
		content: '';
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		background-image: url('/assets/images/icons/plus-white.svg');
		width: 16px;
		height: 16px;
		position: absolute;
		top: calc(50% - 8px);
		left: calc(50% - 8px);
		@include doFadeTransFast();

		@media (max-width: 768px) {
			position: absolute;
			top: calc(50% - 6px);
			left: calc(50% - 6px);
			width: 12px;
			height: 12px;
		}
	}
	&.expand {
		&:after {
			content: '';
			transform: rotate(180deg);
			background-image: url('/assets/images/icons/minus-white.svg');
		}
	}
}

.bottom-border {
	position: relative;
	&:before {
		content: '';
		width: 60%;
		height: 4px;
		background-color: $blue_primary;
		position: absolute;
		left: 20%;
		bottom: -4px;
	}
	&.gradient {
		&:before {
			background: rgb(52, 0, 255);
			background: -moz-linear-gradient(275deg, $blue_secondary 0%, $blue_primary 100%);
			background: -webkit-linear-gradient(275deg, $blue_secondary 0%, $blue_primary 100%);
			background: linear-gradient(275deg, $blue_secondary 0%, $blue_primary 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3400ff",endColorstr="#6cfcfd",GradientType=1);
		}
		&.loading {
			&:before {
				background: linear-gradient(275deg, #696969 0%, #aeaeaf 100%);
			}
		}
	}
}

.lock_icon {
	position: relative;
	width: 11px;
	margin: 0 5px;
	top: -3px;
}

.empty-placeholder {
	display: inline-block;
	background-color: #b4b4b4;
	height: 15px;
	border-radius: 15px;
	width: 100%;
	margin-top: 5px;
}

.seperator {
	height: 3px;
	width: 100%;
	background: rgb(52, 0, 255);
	background: -moz-linear-gradient(275deg, $blue_secondary 0%, $blue_primary 100%);
	background: -webkit-linear-gradient(275deg, $blue_secondary 0%, $blue_primary 100%);
	background: linear-gradient(275deg, $blue_secondary 0%, $blue_primary 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3400ff",endColorstr="#6cfcfd",GradientType=1);
	margin-top: 30px;
	margin-bottom: 30px;
}

a {
	cursor: pointer;
	&:hover {
		color: $black_primary;
		text-decoration: none;
	}
}

.radio_button_wrap {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
	padding-right: 10px;
	strong {
		margin-right: 20px;
	}
	.radio_container {
		position: relative;
		height: 30px;
		width: 30px;
		margin: 0 !important;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			&:checked {
				~ {
					.checkmark {
						background-color: $blue_primary;
						border-color: $blue_primary;
						&:after {
							display: block;
						}
					}
				}
			}
		}
		&:hover {
			input {
				~ {
					.checkmark {
						opacity: 0.6;
					}
				}
			}
		}
		.checkmark {
			position: absolute;
			top: 0;
			left: 0;
			height: 30px;
			width: 30px;
			background-color: $white_primary;
			border: 1px solid rgba($color: $grey_secondary, $alpha: 0.1);
			border-radius: 10px;
			box-shadow: 0px 0px 5px 0px rgba($color: $grey_secondary, $alpha: 0.1);
			&:after {
				content: '';
				position: absolute;
				left: 11px;
				top: 2px;
				width: 6px;
				height: 20px;
				border: solid $black_primary;
				border-width: 0 2px 2px 0;
				-webkit-transform: rotate(42deg);
				-ms-transform: rotate(42deg);
				transform: rotate(42deg);
				display: none;
			}
		}
	}
	&.disabled {
		opacity: 0.5;
        cursor: not-allowed;
	}
}

.select_wrap {
	position: relative;
	border: none;
	border-radius: 15px;
	padding-top: 0;
	padding-bottom: 0;
	background-color: $black_primary;
	overflow: hidden;

	select {
		-webkit-appearance: none;
		appearance: none;
		width: 100%;
		height: 45px;
		background: transparent;
		color: $white_primary;
		border: none;
		padding: 0 15px;
		padding-right: 35px;
		background: $black_primary !important;
		&:focus,
		&:active {
				color: $white_primary;
		}

		&:focus {
				outline: none;
				text-decoration: none;
		}
	}

	&:after {
		display: block;
		font-family: 'FontAwesome';
		content: '\f107';
		position: absolute;
		top: 7px;
		right: 10px;
		pointer-events: none;
		color: $white_primary;
		font-size: 20px;
	}

	&.grey {
		background-color: $grey_lite;

		select {
			color: $grey_secondary;
			background: $grey_lite !important;
			&:focus,
			&:active {
					color: $grey_secondary;
			}
		}

		&:after {
			color: $grey_secondary;
			top: 10px;
		}
	}
	&.white {
		background-color: $white_primary;

		select {
			color: $black_primary;
			background: $white_primary !important;
			&:focus,
			&:active {
					color: $black_primary;
			}
		}

		&:after {
			color: $black_primary;
			top: 8px;
			right: 11px;
		}
	}
}

//select 2 css
.select2 {
	margin: 0px !important;
	padding: 12px 20px !important;
	width: 100% !important;
	border: none !important;
	font-size: 18px !important;
	background-color: $white_primary !important;
	color: $black_primary;
	border-radius: 26px;
	outline: none !important;
	.select2-selection {
		background-color: transparent !important;
		outline: none !important;
		border: none !important;

		.select2-selection__rendered {
			padding: 0 !important;
			font-size: 22px;
			color: $black_primary;
			font-family: $gilroy_bold;
		}

		.select2-selection__arrow {
			top: calc(50% - 15px) !important;
			font-size: 15px !important;
			right: 2px !important;
			&:after {
				content: '\f107' !important;
				display: inline-block !important;
				font-family: 'FontAwesome' !important;
				font-size: 20px !important;
			}
			b {
				display: none !important;
			}

			&.stm-preloader {
				&:after {
					content: '\f110';
					animation: rotating 2s infinite linear;
					font-size: 13px;
					color: $white_primary;
				}
			}
		}
	}
}

// Hide disabled values
.select2-container--default .select2-results__option[aria-disabled='true'] {
	display: none;
}

.select2-container {
	.select2-dropdown {
		min-width: 200px;
		border: 1px solid $grey_lite_2 !important;

		.select2-results__option {
			&.select2-results__option--highlighted {
				color: $black_primary;
				background-color: $blue_primary !important;
			}
		}
	}
}

circle-progress {
	text {
		tspan {
			font-family: $gilroy_bold;
			font-weight: 900;
			font-size: 18px;
			fill: $black_primary;

			@media (max-width: 767px) {
				font-size: 24px;
			}
		}
	}

	&.smooth svg path {
		transition: stroke 1s ease;
	}

	&.fast svg path {
		transition: stroke 0s ease;
	}
}

//position fixed when mobile menu is expanded
// body.menu-open header{
//     position: fixed;
//     width: 100%;
//     z-index: 999999;
// }

.tooltip.show {
	font-family: $gilroy_semiBold;
}

// ONBOARDING GLOBAL //
.onboarding_wrapper {
	color: $white_primary;
	position: relative;
	min-height: 100vh;

	.heading,
	.body {
		color: $white_primary;
	}

	.outer {
		//padding-top: 150px;
		@media (max-height: 730px) {
			//margin-top: 50px;
		}
		@media (min-height: 650px) {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			//padding-top: 0;
		}

		@media (max-width: 575px) {
			position: relative;
			transform: none;
			left: 0;
			top: 0;
			padding-top: 20px;
		}
	}

	.page_title,
	.heading {
		font-size: 30px;
		@media (max-width: 767px) {
			font-size: 26px;
		}
	}
	.heading {
		&.small {
			font-size: 24px;
		}
	}

	.onboard_wrap {
	}

	.bg_image {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: bottom right;
		background-image: url('/assets/images/backgrounds/Onboarding-BG.jpg');
		&.mob {
			background-position: center center;
			background-image: url('/assets/images/backgrounds/Onboarding-BG-Mobile.jpg');
			@media (min-width: 769px) {
				display: none;
			}
		}
	}

	.floating_logo {
		position: absolute;
		left: 40px;
		top: 40px;
		z-index: 99;
		a {
			display: inline-block;
			max-width: 135px;
			@media (max-width: 767px) {
				// max-width: 105px;
			}
			svg, img {
				width: 100%;
				height: auto;
			}
		}
		@media (max-width: 991px) {
			left: 15px;
			top: 30px;
		}
		@media (max-width: 575px) {
			text-align: center;
			left: 0;
			right: 0;
			position: relative;
			top: 0;
			padding-top: 30px;
		}
	}

	.col_form {
		max-width: 560px;
		padding: 0 15px;
		margin: 0 auto;
	}

	.subheading {
		padding-top: 10px;
		position: relative;
		max-width: 600px;
		margin: 0 auto;
		@media (max-width: 767px) {
			font-size: 14px;
		}
		p {
			font-family: $gilroy_semiBold;
		}
		a {
			color: $blue_primary;
			font-family: $gilroy_semiBold;
			text-decoration: underline;
		}
	}

	.invalid-feedback {
		color: #fb848f;
	}
}

.mobile_link_bottom {
	text-align: right;
	padding-top: 15px;
	@media (min-width: 576px) {
		display: none;
	}
}

.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next::after {
	content: '' !important;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-image: url(/assets/images/icons/long-arrow-black.svg);
	width: 24px;
	height: 10px;
}

.pagination-next,
.pagination-previous {
	> span {
		display: none;
	}
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous::before {
	content: '' !important;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-image: url(/assets/images/icons/long-arrow-black.svg);
	transform: rotate(180deg);
	width: 24px;
	height: 10px;
}

.col-lg-10.offset-lg-1 {
	@media (min-width: 992px) and (max-width: 1199px) {
		flex: 0 0 90%;
		max-width: 90%;
		margin-left: 5%;
	}
}

.row {
	@media (max-width: 575px) {
		margin-left: -10px;
		margin-right: -10px;
	}
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
	@media (max-width: 575px) {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.container {
	@media (max-width: 575px) {
		padding-left: 10px;
		padding-right: 10px;
	}
}

ul.features {
	list-style: none;
	padding-left: 0;
	text-align: left;
	li {
		display: table;
		padding-bottom: 10px;
		&:last-child {
			padding-bottom: 0;
		}
		svg, img {
			display: inline-block;
			top: 4.5px;
			margin-right: 10px;
			position: relative;
		}

		img {
			top: -1.5px;
		}
		span,
		svg {
			display: table-cell;
		}

		span.feat_desc {
			display: block;
			font-size: 14px;
			color: #545454;
		}

		&.loading-text {
			width: 100%;
			text-align: center;
			height: 20px;
			padding: 10px;
			color: $white_primary;
			margin-bottom: 20px;

			&:last-child {
				width: 50%;
				margin-bottom: 0;
			}
		}
	}
}

.radio_wrap {
	width: 100%;
	position: relative;
	margin: 0;
	display: table;
	overflow: hidden;
	//background-color: $radio_wrap_background;
	background-color: #2b2a2a;
	padding: 5px;
	border-radius: 100px;
	max-width: 400px;
	margin: 0 auto;
	margin-bottom: 20px;
	margin-top: 20px;

	@media (max-width: 575px) {
		margin: 0 5px;
		width: calc(100% - 10px);
	}

	input[type='radio'] {
		position: absolute;
		right: 1000%;
	}

	label {
		display: table-cell;
		width: 33.33%;
		margin: 0 2.5%;
		border-radius: 50%;
		cursor: pointer;
		position: relative;
		z-index: 1;
		text-align: center;
		.btn {
			background-color: transparent;
			border: none;
			color: $white_primary;
			font-size: 15px;
			font-family: $gilroy_semiBold;
			padding: 15px 20px;
			margin-top: 0;
			&:hover {
				color: $blue_primary;
			}

			@media (max-width: 700px) {
				padding: 10px 10px;
			}

			@media (max-width: 620px) {
				font-size: 14px;
			}

			@media (max-width: 520px) {
				font-size: 13px;
				padding: 10px 5px;
			}
		}
	}

	input[type='radio']:checked + label {
		.btn {
			color: $white_primary;
			color: $black_primary;
		}
	}

	.slider {
		position: absolute;
		left: 5px;
		top: 7px;
		width: calc(33.33% - 7px);
		height: calc(100% - 14px);
		background: $blue_primary;
		border-radius: 100px;
		z-index: 0;
		@include doFadeTrans();
	}

	#weekly:checked ~ .slider {
		left: 7px;
	}
	#monthly:checked ~ .slider {
		left: calc(33.33% + 3.5px);
	}
	#yearly:checked ~ .slider {
		left: calc(66.66%);
	}

	#all:checked ~ .slider {
		left: 10px;
	}
	#horses:checked ~ .slider {
		left: calc(25% + 1.5px);
	}
	#jockeys:checked ~ .slider {
		left: calc(50% + 1px);
	}
	#trainers:checked ~ .slider {
		left: calc(75% - 1.5px);
	}

	#form-guide-radio:checked ~ .slider {
		left: 7.5px;
	}
	#result-radio:checked ~ .slider {
		left: 7.5px;
	}
	#speed-map-radio:checked ~ .slider {
		left: calc(33.333333333% + 4px);
	}
	#live-yard-radio:checked ~ .slider {
		left: 66.666666666%;
	}

	&.switch_4 {
		label {
			width: 25%;
		}
		.slider {
			width: calc(25% - 2.5px);
		}

		#form-guide-radio:checked ~ .slider {
			left: 5px;
		}
		#result-radio:checked ~ .slider {
			left: 5px;
		}
		#speed-map-radio:checked ~ .slider {
			left: calc(25% + 2.5px);
		}
		#preview-radio:checked ~ .slider {
			left: 50%;
		}
		#review-radio:checked ~ .slider {
			left: 50%;
		}
		#live-yard-radio:checked ~ .slider {
			left: calc(75% - 2.5px);
		}
	}

	&.switch_5 {
		label {
			width: 20%;
		}
		.slider {
			width: calc(20% - 5px);
		}
		#form-guide-radio:checked ~ .slider {
			left: 7.5px;
		}
		#result-radio:checked ~ .slider {
			left: 7.5px;
		}
		#speed-map-radio:checked ~ .slider {
			left: calc(20% + 5px);
		}
		#preview-radio:checked ~ .slider {
			left: calc(40% + 2.5px);
		}
		#review-radio:checked ~ .slider {
			left: 60%;
		}
		#live-yard-radio:checked ~ .slider {
			left: calc(80% - 1px);
		}
	}

	&.switch_2 {
		#today:checked ~ .slider {
			left: 10px;
		}
		#tomorrow:checked ~ .slider {
			left: calc(50% - 0px);
		}
		.slider {
			width: calc(50% - 10px);
		}
		label {
			width: 50%;
		}
	}

	&.grey {
		background-color: $grey_lite;
		label {
			.btn {
				color: $black_primary;
			}
		}
		input[type='radio']:checked + label {
			.btn {
				color: $white_primary;
				//color: $blue_secondary;
			}
		}
		.slider {
			background-color: $black_primary;
		}
	}
}

.bubble_chat {
	position: relative;
	&:before,
	&:after {
		content: '';
		position: absolute;
	}

	&::after {
		width: 20px;
		background-color: $grey_lite;
		border-bottom-left-radius: 12px;
		bottom: 3px;
		right: -20px;
		height: 21px;
	}

	&::before {
		right: -8px;
		width: 23px;
		background-color: $grey_lite_2;
		border-bottom-left-radius: 16px 14px;
		bottom: 2px;
		height: 19px;
	}

	&.bubble_left {
		&:after {
			right: unset;
			left: -20px;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 12px;
		}
		&:before {
			right: unset;
			left: -8px;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 16px 14px;
		}
	}

	&.bubble_white_bg {
		&:after {
			background-color: $white_primary;
		}
	}

	&.bubble_lite {
		&::before {
			background-color: $grey_lite;
		}
	}

	.bottom_bar {
		display: flex;
		padding-top: 20px;
		.author_wrap {
			flex: 1;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			width: 100%;
			align-items: center;
			
			span {
				font-family: $gilroy_semiBold;
			}
			.avatar_inner {
				border: 2px solid $white_primary;
				margin-right: 5px;
				width: 46px;
				border-radius: 100%;
				height: 46px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
			}
		}
		.confidence_wrap {
			flex: 0 0 145px;
			max-width: 145px;
			text-align: right;
		}
	}
}

.info_circle {
	cursor: pointer;
	height: 15px;
	width: 15px;
	background-color: $black_primary;
	border-radius: 100%;
	position: relative;
	display: inline-block;
	top: -1px;
	text-align: center;
	padding-top: 1px;
	margin-left: 4px;
	line-height: 12px;
	font-size: 10px;
	color: $white_primary;
	i {
		color: $white_primary;
		font-size: 10px;
		font-style: normal !important;
	}
	span {
		font-size: 10px !important;
	}
}

.terms_and_conditions_wrapper,
.privacy_policy_wrapper {
	padding-bottom: 50px;
	h2 {
		font-family: $gilroy_semiBold;
		padding-bottom: 10px;
	}
	h4 {
		font-size: 18px;
		font-family: $gilroy_semiBold;
		margin-bottom: 5px;
	}

	a {
		color: $blue_secondary;
	}

	li {
		margin-bottom: 5px;
	}
}

.grecaptcha-badge {
	visibility: hidden;
}

.analyst-img {
	width: 52px;
	height: 52px;
	border-radius: 100%;
	border: 2px solid $grey_lite_2;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	
	&.ongoing {
		border-color: $green_primary;
	}
}

perfect-scrollbar {
	.ps-content {
		padding-bottom: 20px;
	}	
	.ps__rail-y {
		background-color: transparent !important;

		.ps__thumb-y {
			background-color: $blue_primary !important;
		}
	}
}


/* UPDTE TO SUBSCRIPTION LANDING PAGE */
.landing_content_1 {
	padding-top: 40px;
	h2 {
		margin-bottom: 20px;
	}
	.colbox {
		@media (max-width: 991px) {
			margin-bottom: 15px;
		}
	}
	.innerbox {
		background-color: $white_primary;
		border-radius: 26px;
		padding: 25px;
		color: $black_primary;
		height: 100%;

		h4 {
			font-family: $gilroy_bold;
		}

		h5 {
			font-family: $gilroy_semiBold;
			font-size: 16px;
			margin-bottom: 5px;
		}

		ul {
			margin-bottom: 0;
			li {
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
}

.landing_content_2 {
	padding: 30px 0;
	.video-container {
	    position: relative;
	    width: 100%;
	    padding-top: 56.25%; /* 16:9 Aspect Ratio */
	    overflow: hidden;
	    border-radius: 26px;
	}

	.video-container iframe {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	}
}