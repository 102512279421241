.accr-section {
	margin: 0;
	padding: 0;
	list-style: none;

	.collapsable-section {
		border-radius: 26px;
		margin-bottom: 30px;
		padding: 22px;
		position: relative;
		background-color: $grey_lite;

		&:last-child {
			margin-bottom: 0;
		}

		.collapsed-content {
			height: 0;
			opacity: 0;
			overflow: hidden;
		}

		&.closed {
			.collapsed-content {
				pointer-events: none;
			}
		}
	}
}
