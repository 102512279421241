.locked_panel {
	padding: 30px;
	@media (max-width: 575px) {
		padding: 30px 15px;
	}
	h2 {
		padding-bottom: 10px;
	}

	.placeholder {
		text-align: center;
		img {
			width: 100%;
			max-width: 600px;
		}
	}

	.body {
		max-width: 700px;
		margin: 0 auto;
		.btn {
			display: block;
			max-width: 250px;
			width: 100%;
			margin: 20px auto;
		}
	}
}
