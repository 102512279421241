.previews_widget {
	margin-bottom: 40px;
	h2.heading {
		font-size: 18px;
	}
	.previews_single {
		margin-top: 10px;
		&:first-child {
			margin-top: 15px;
		}

		.panel {
			border-radius: 20px;
			padding: 10px 15px;
			.next_race {
				display: inline-block;
				background-color: $grey_lite;
				color: $black_primary;
				font-family: $gilroy_semiBold;
				font-size: 14px;
				padding: 7px;
				padding-left: 10px;
				text-align: center;
				border-radius: 30px;
				align-self: center;
				line-height: 12px;
				a {
				}

				span.next_text {
					vertical-align: middle;
					padding-right: 3px;
					padding-left: 2px;
				}

				span.race_no {
					background-color: $black_primary;
					color: $white_primary;
					width: 30px;
					height: 30px;
					border-radius: 100%;
					text-align: center;
					display: inline-block;
					line-height: 30px;
					//top: 5px;
					margin-left: 5px;
					position: relative;
					p {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						margin-left: -1px;
						//margin-top: 0.5px;
					}
				}
			}
			.p-row {
				.col_title_link {
					flex: 0 0 calc(100% - 160px);
					max-width: calc(100% - 160px);
				}
				.col_next_race {
					flex: 0 0 160px;
					max-width: 160px;
					padding-left: 10px;
					.loading-text {
						float: right;
						&:before {
							content: '';
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							height: 28px;
							width: 28px;
							background-color: $black_primary;
							opacity: 0.3;
							right: 5px;
							border-radius: 100%;
						}
					}
				}
			}
		}

		.loading-text {
			width: 80%;
			height: 36px;
			margin-top: 3px;
			margin-bottom: 3px;
		}
	}
}
