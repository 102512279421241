// colours
$black_primary: #000000;
$white_primary: #ffffff;
$grey_primary: #f5f5f7;
$grey_secondary: #777777;
$grey_lite: #f9f9fb;
$grey_lite_2: #ededf0;
$grey_lite_3: #f0f0f0;
$grey_lite_4: #f7f7f7;
$grey_lite_5: #f4f4f8;
$grey_lite_6: #5c5c5c;
$grey_lite_7: #dbdbdb;
$blue_primary: #6cfcfd;
$pink_primary: #9d00ff;
$blue_secondary: #2700ff;
$dark_grey: #2b2a2a;
//$blue_secondary:	$pink_primary;
//$blue_secondary:	#674cff;

$blue_dark: #203473;
$red_primary: #ff0101;
$orange_primary: #ff6601;
$green_primary: #67be53;
$pink_secondary: #f694aa;
$yellow_primary: #fdb92c;

//fonts
$stack: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
// Typography
$gilroy_thin: 'Gilroy-Thin', $stack;
$gilroy_light: 'Gilroy-Light', $stack;
$gilroy_ultralight: 'Gilroy-UltraLight', $stack;
$gilroy_regular: 'Gilroy-Regular', $stack;
$gilroy_medium: 'Gilroy-Medium', $stack;
$gilroy_semiBold: 'Gilroy-SemiBold', $stack;
$gilroy_bold: 'Gilroy-Bold', $stack;
//$gilroy_extraBold:		'Gilroy-ExtraBold', $stack;
$gilroy_extraBold: $gilroy_bold;
$anton-regular: 'Anton-Regular', $stack;
$benzin-semibold: 'Benzin-Semibold', $stack;

$border_radius: 26px;
$modal_border_radius: 30px;

@mixin doFadeTransSlow() {
	transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-webkit-transition: all 0.1s ease-in-out;
}

@mixin doFadeTrans() {
	transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
}

@mixin doFadeTransFast() {
	transition: all 0.15s ease-in-out;
	-moz-transition: all 0.15s ease-in-out;
	-webkit-transition: all 0.15s ease-in-out;
}

@mixin doFadeTransFastest() {
	transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-webkit-transition: all 0.1s ease-in-out;
}

@mixin blueGradient() {
	background: rgb(52, 0, 255);
	background: -moz-linear-gradient(275deg, $blue_secondary 0%, $blue_primary 100%);
	background: -webkit-linear-gradient(275deg, $blue_secondary 0%, $blue_primary 100%);
	background: linear-gradient(275deg, $blue_secondary 0%, $blue_primary 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3400ff",endColorstr="#6cfcfd",GradientType=1);
}

.loading-text {
	display: block;
	position: relative;
	background-color: $grey_secondary;
	opacity: 0.6;
	width: 100%;
	border-radius: 20px;
	overflow: hidden;

	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		transform: translateX(-100%);
		background: -webkit-gradient(
			linear,
			left top,
			right top,
			from(transparent),
			color-stop(rgba(179, 175, 175, 0.2)),
			to(transparent)
		);
		background: linear-gradient(90deg, transparent, rgba(179, 175, 175, 0.2), transparent);
		/* Adding animation */
		animation: loading 1.5s infinite;
	}

	&.white {
		background-color: $white_primary;
	}

	&.square {
		border-radius: 0;
	}
}

@keyframes loading {
	100% {
		transform: translateX(100%);
	}
}
