.news_vertical_widget {
	margin-bottom: 40px;

	.panel {
		padding: 32px 22px;
		padding-top: 35px;
		.view_links {
			.arrow_link.small {
				top: 4px;
			}
		}
		.news_single {
			margin-top: 40px;
			position: relative;
			&:first-child {
				margin-top: 30px;
			}

			&:last-child {
				margin-bottom: 0;
			}

			@media (min-width: 1200px) {
				&:nth-of-type(3) {
					display: none;
				}
			}
		}
	}

	&.horizontal {
		margin-bottom: 15px;
		> .col-12 {
			@media (max-width: 575px) {
				//padding-left: 5px;
				//padding-right: 5px;
			}
		}
		.news_single {
			margin-top: 20px;
			&:first-child {
				margin-top: 40px;
				margin-top: 20px;
			}
			@media (max-width: 850px) {
				flex: 0 0 50%;
				max-width: 50%;
				&:nth-of-type(3) {
					display: none;
				}
			}
			@media (max-width: 575px) {
				padding-left: 10px;
				padding-right: 10px;
				flex: 0 0 100%;
				max-width: 100%;
				&:nth-of-type(3) {
					display: block;
				}
			}
		}
		.panel {
			background-color: transparent;
			padding-left: 0;
			padding-right: 0;
			padding-top: 10px;
			article {
				.content {
					background-color: $white_primary;
					.title_wrap {
						> div {
							@media (max-width: 1150px) {
								flex: 0 0 100%;
								max-width: 100%;
								&.text-right {
									text-align: left !important;
									padding-left: 15px !important;
									padding-top: 10px;
								}
							}
						}
						@media (max-width: 575px) {
							margin-left: -15px;
							margin-right: -15px;
						}
					}
				}
			}
		}

		.view_links {
			@media (max-width: 575px) {
				padding-top: 20px;
				text-align: center !important;
			}
		}
	}
}
