.trc-tabs {
	&.nav-tabs {
		border: none;
		padding: 0 15px;
		position: relative;

		li {
			position: relative;
			z-index: 1;
			margin-left: 15px;
			a {
				position: relative;
				font-size: 15px;
				font-family: $gilroy_bold;
				background-color: $black_primary;
				color: $white_primary;
				border: none;
				display: inline-block;
				padding: 18px;
				padding-bottom: 14px;
				border-top-left-radius: 26px;
				border-top-right-radius: 26px;
				min-width: 150px;
				text-align: center;
				@include doFadeTransFast();
				@media (max-width:1280px) {
					min-width: 140px ;
				}

				&.confidence-link {
					padding-right: 45px;
				}

				> span,
				.wrapped {
					margin: 0 auto;
				}

				span.count {
					border-radius: 100%;
					background-color: $grey_secondary;
					font-family: $gilroy_bold;
					border-radius: 100%;
					font-size: 12px;
					min-width: 22px;
					height: 22px;
					display: inline-block;
					margin-left: 5px;
					text-align: center;
					@include doFadeTrans();
					vertical-align: middle;
					top: -2px;
					position: relative;
					line-height: 22px;
				}

				span.confidence {
					display: inline-block;
					padding: 6px 3px;
					border-radius: 8px;
					min-height: 30px;
					min-width: 30px;
					position: absolute;
					top: calc(50% - 14px);
					margin-left: 8px;
					background-color: rgba($grey_primary, 0.2);
					p {
						margin-bottom: 0;
						font-size: 13px;
						&:after {
							content: '%';
						}
					}
					&.green {
						background-color: $green_primary;
						p {
							color: $white_primary !important;
						}
					}
					&.yellow {
						background-color: $yellow_primary;
						p {
							color: $white_primary !important;
						}
					}
				}

				&.active {
					text-decoration: none;
					outline: none;
					color: $black_primary;
					background-color: $white_primary;

					span.count {
						background-color: $grey_primary;
					}
					&:hover {
						background-color: $white_primary !important;
						span.count {
							background-color: $grey_primary;
						}
					}
				}

				&:hover {
					text-decoration: none;
					outline: none;
					//color: $black_primary;
					background-color: rgba($black_primary, 0.8);

					span.count {
						//background-color: $grey_primary;
						//color: $black_primary;
					}
				}
			
				@media (max-width: 1100px) {
					min-width: 125px;
				}
			
				@media (max-width: 350px) {
					font-size: 13px;
					min-width: auto;
					padding: 16px 14px;
				}
			}
			&:last-child {
				margin-right: 0;
			}

			@media (max-width: 991px) {
				margin-left: 10px;
			}

			&.disabled {
				a {
					background-color: rgba($color: $black_primary, $alpha: 0.4);
				}
			}
		}
	}

	&.tab-content {
		background-color: $white_primary;
		border-radius: $border_radius;
		padding: 20px;
		@include doFadeTrans();
		@media (max-width: 575px) {
			padding: 15px;
		}

		&.no-padding {
			padding: 0;

			@media (max-width: 767px) {
				padding: 20px;
			}

			@media (max-width: 575px) {
				padding: 15px;
			}
		}

		.accr-section {
		}
	}
}
