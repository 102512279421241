.comments {
	position: relative;
	background-color: $grey_lite_2;
	border-radius: 26px;
	padding: 15px;
	margin-bottom: 20px;
	> .inner {
		span,
		p {
			font-size: 13px;
			font-family: $gilroy_medium;
			display: block;
			white-space: pre-wrap;
			word-break: break-word;
		}
		strong {
			font-family: $gilroy_semiBold;
			font-weight: normal;
		}
	}

	.avatar {
		position: absolute;
		right: 15px;
		// top: calc(50% - 21px);
		top: unset;
		bottom: 27px;
		width: 42px;
		height: 42px;
		z-index: 1;
		border-radius: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}

	&.with-avatar {
		> .inner {
			padding-right: 60px;
		}
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
	}

	&::after {
		width: 20px;
		background-color: $white_primary;
		border-bottom-left-radius: 12px;
		bottom: 3px;
		right: -20px;
		height: 20px;
	}

	&::before {
		right: -8px;
		width: 23px;
		background-color: $grey_lite_2;
		border-bottom-left-radius: 16px 14px;
		bottom: 2px;
		height: 21px;
	}

	&.with-avatar-outside {
		background-color: transparent;
		border-radius: 0;
		padding: 0;
		&:before,
		&:after {
			display: none;
		}

		.avatar {
			right: 0;
		}

		> .inner {
			position: relative;
			border-radius: 26px;
			padding: 15px;
			width: calc(100% - 50px);
			background-color: $grey_lite_2;
			&:before,
			&:after {
				content: '';
				position: absolute;
			}

			&::after {
				width: 20px;
				background-color: $white_primary;
				border-bottom-left-radius: 12px;
				bottom: 3px;
				right: -20px;
				height: 20px;
			}

			&::before {
				right: -8px;
				width: 23px;
				background-color: $grey_lite_2;
				border-bottom-left-radius: 16px 14px;
				bottom: 2px;
				height: 21px;
			}
		}

		&.blue {
			> .inner {
				color: $white_primary;
				background-color: $blue_secondary;

				&::after {
					background-color: $white_primary;
				}

				&::before {
					background-color: $blue_secondary;
				}
			}
		}

		&.right-align {
			.avatar {
				left: 0;
				right: unset;
				border-radius: 100%;
			}

			> .inner {
				margin-left: auto;
				&::after {
					width: 20px;
					background-color: $white_primary;
					border-bottom-right-radius: 25px;
					bottom: 3px;
					right: unset;
					left: -20px;
					height: 20px;
				}

				&::before {
					left: -13px;
					right: unset;
					width: 23px;
					background-color: $grey_lite_2;
					border-bottom-right-radius: 16px 14px;
					bottom: 0px;
					height: 23px;
				}
			}
		}
		.date {
			font-family: $gilroy_semiBold;
			font-size: 10px;
			color: $grey_secondary;
			margin-left: 10px;
			top: -5px;
			position: relative;
			left: 5px;
		}
	}

	&.full_width {
		.inner {
			width: 100% !important;
		}
	}

	.bottom_bar {
		display: flex;
		.author_wrap {
			flex: 1;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			width: 100%;
			align-items: center;
			> * {
				display: flex;
				flex-direction: column;
				flex-basis: 100%;
				flex: 1;
			}
			.avatar_inner {
				border: 2px solid $white_primary;
				margin-right: 5px;
				border-radius: 100%;
				max-width: 46px;
				width: 46px;
				height: 46px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
			}
			span {
				font-family: $gilroy_semiBold;
			}
		}
		.confidence_wrap {
			flex: 0 0 145px;
			max-width: 145px;
			text-align: right;
		}
	}
	&.count {
		margin: 0;
		border-radius: 10px;
		padding: 10px 5px;
		font-size: 12px;
		font-family: $gilroy_bold;
		text-align: center;
		min-width: 20px;
		> .inner {
			position: relative;
			z-index: 1;
		}
	}
}