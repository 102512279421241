.limited_time_offer {
	.panel {
		background-color: $black_primary;
		overflow: hidden;
		@media (max-width: 767px) {
			margin-bottom: 17px;
		}
		.col_content {
			position: relative;
			padding: 40px 50px;
			padding-right: 15px;
			@media (max-width: 991px) {
				padding-right: 40px;
			}
			@media (max-width: 767px) {
				padding-top: 30px;
				padding-bottom: 30px;
				padding-left: 30px;
				padding-right: 30px;
			}
			> .inner {
				position: relative;
				z-index: 2;
				max-width: 380px;
				@media (max-width: 575px) {
					max-width: 100%;
				}
			}

			.title {
				font-size: 22px;
				font-family: $gilroy_extraBold;
				line-height: 36px;
				color: $white_primary;
				margin-bottom: 10px;
				@media (max-width: 1480px) {
					font-size: 26px;
					line-height: 32px;
				}
				@media (max-width: 767px) {
					font-size: 20px;
				}
				&.loading-text {
					min-height: 25px;
					max-width: 280px;
				}
			}

			.body {
				margin-bottom: 30px;
				@media (max-width: 767px) {
					max-width: 90%;
				}
				&.loading-text {
					min-height: 25px;
					max-width: 120px;
				}
			}
			.btn {
				font-family: $gilroy_semiBold;

				@media (max-width: 575px) {
					width: 100%;
					//font-size: 13px;
				}
				&.blue {
					margin-left: 10px;
				}
				&.loading-text {
					min-height: 25px;
					max-width: 200px;
				}
				&:first-of-type {
					margin-right: 10px;
					&:hover {
						border: 1px solid $black_primary;
						background: $white_primary;
						color: $black_primary;
					}
				}
				&:nth-of-type(2) {
					background: $blue_primary;
					border: 1px solid $blue_primary;
					color: $black_primary;
					&:hover {
						background: $white_primary;
						border: 1px solid $white_primary;
					}
					@media (max-width: 575px) {
						margin-top: 10px;
					}
				}
			}
			.image_mobile {
				position: absolute;
				z-index: 1;
				width: 100%;
				height: 100%;
				bottom: 0;
				right: 0;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center right;
				display: none;
				opacity: 0.9;
				@media (max-width: 767px) {
					display: block;
				}
				@media (max-width: 725px) {
					opacity: 0.6;
				}
				@media (max-width: 675px) {
					opacity: 0.5;
					width: 75%;
					background-size: contain;
					background-position: center bottom;
				}
			}
		}
		.col_img {
			padding-left: 0;
			@media (max-width: 767px) {
				display: none;
			}
			> .inner {
				width: 100%;
				height: 100%;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center bottom;
			}
		}
	}

	&.sidebar {
		.col_content {
			padding: 30px 22px;
		}
		.panel {
			height: 100%;
			@media (max-width: 767px) {
				height: auto;
			}
			.btn {
				display: block;
				max-width: 100%;
				margin-right: 0 !important;
				margin-bottom: 8px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
