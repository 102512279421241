/* You can add global styles to this file, and also import other style files */
@import 'assets/styles/common/variables';
@import 'assets/styles/common/global';
@import '../node_modules/ngx-toastr/toastr.css';
// COMPONENTS
@import 'assets/styles/components/accordions';
@import 'assets/styles/components/modals';
@import 'assets/styles/components/news_article';
@import 'assets/styles/components/tabs';
@import 'assets/styles/components/blackbook';
@import 'assets/styles/components/locked_panels';
@import 'assets/styles/components/comments';
// /* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

// WIDGETS
@import 'assets/styles/widgets/previews_widget';
@import 'assets/styles/widgets/download_app_widget';
@import 'assets/styles/widgets/news_vertical_widget';
@import 'assets/styles/widgets/limited_offer_widget';
@import 'assets/styles/widgets/resources_widget';
