.download_app_widget {
	text-align: center;
	.panel {
		position: relative;
		padding: 40px 30px;
		height: 80%;
		background-image: url('/assets/images/TRC-App-Mockup-BG-small.jpg');
		background-size: cover;
		max-width: 450px;
		margin: 0 auto;
		.heading,
		.body {
			margin-bottom: 20px;
			p,
			a {
				color: $white_primary;
			}
		}
		.play_store_links {
			margin-bottom: 20px;
			display: inline-flex;
			border-radius: 32px;
			padding: 7px 10px;
			@include blueGradient();
			a {
				position: relative;
				width: 40px;
				height: 40px;

				background-color: $white_primary;
				text-align: center;
				border-radius: 100%;
				@include doFadeTrans();
				img {
				}
				.svg_wrapper {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 14px;
					line-height: 12px;
					svg {
						width: 100%;
						height: auto;
					}
				}
				&:first-child {
					margin-right: 10px;
					.svg_wrapper {
						margin-top: -1px;
						margin-left: -1px;
					}
				}
				&:hover {
					text-decoration: none;
					outline: none;
					.svg_wrapper {
						margin-right: -1.5px;
					}
				}
			}
		}
		.floting_phone_img {
			position: relative;
			width: 100%;
		}
	}
}
