article {
	height: 100%;
	position: relative;

	&.featured {
		.content {
			.title_wrap {
				h3 {
					font-size: 20px;
				}
			}
		}
	}
	.news_img {
		position: relative;
		z-index: 1;
		width: 100%;
		height: 260px;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		background-color: rgba($color: $black_primary, $alpha: 0.6);
		border-radius: $border_radius;
		@media (max-width: 991px) {
			height: 260px;
		}

		@media (max-width: 480px) {
			height: 240px;
		}

		.loading-text {
			height: 260px;
			width: 100%;
		}
	}
	.content {
		position: relative;
		z-index: 2;
		border-radius: $border_radius;
		background-color: $grey_lite;
		padding: 22px;
		color: $black_primary;
		margin-top: -80px;
		.title_wrap {
			margin-bottom: 15px;
			h3 {
				font-size: 16px;
				font-family: $gilroy_bold;
				a {
					color: $black_primary;
				}
				.loading-text {
					width: 100%;
					max-width: 180px;
					height: 20px;
					margin-bottom: 15px;
					background-color: $grey_secondary;
					opacity: 0.6;
				}
			}
			.date {
				font-size: 15px;
				font-family: $gilroy_medium;
				color: $black_primary;
			}
			@media (max-width: 1500px) and (min-width: 1200px) {
				> .col {
					flex: 0 0 100%;
					max-width: 100%;
					&.text-right {
						margin-top: 5px;
						padding-left: 15px !important;
						text-align: left !important;
					}
				}
			}
			@media (max-width: 480px) {
				> .col {
					flex: 0 0 100%;
					max-width: 100%;
					&.text-right {
						margin-top: 5px;
						padding-left: 15px !important;
						text-align: left !important;
					}
				}
			}
		}
		.body {
			margin-bottom: 15px;
			.loading-text {
				max-width: 100%;
				height: 18px;
				margin-bottom: 10px;
			}
		}
		.loading-text {
			max-width: 110px;
			height: 18px;
			margin-bottom: 10px;
		}
	}
}
