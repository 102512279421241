.login-modal,
.signup-modal,
.blackbook-modal,
.choice-modal,
.upgrade-modal,
.upgrade-modal,
.modal {
	display: flex !important;
	align-items: center !important;
	backdrop-filter: blur(15px) brightness(190%) opacity(1) !important;

	.modal-dialog {
		flex-grow: 1;
	}

	img {
		padding: 20px;
	}
}


.login-modal {
	.modal-dialog {
		&.modal-custom {
			max-width: 500px;
		}
	}
}
.modal-backdrop.show {
	opacity: 1;
}
.modal-backdrop {
	background-color: rgba(0, 0, 0, 0.55) !important;
}

.modal-dialog {
	&.modal-custom {
		max-width: 600px;
	}

	.modal-content {
		background-color: transparent;
		border-radius: none;
		box-shadow: none;
		border: none;

		.form_wrapper {
			.form-group {
				input[type='text'],
				input[type='password'] {
					background-color: $grey_lite !important;
					border-radius: 15px !important;
				}

				.select2 {
					padding: 0 !important;
					width: auto !important;
					padding-right: 30px !important;
				}

				.btn {
					border-radius: 15px !important;
				}

				.modal_link,
				.form-check-label {
					color: $black_primary !important;
				}
			}
		}
	}
}

.blackbook-modal {
	.modal-dialog {
		&.modal-custom {
			max-width: 800px;
		}
	}
}

.modal-lg {
	@media (min-width: 992px) {
		max-width: 500px;
	}
}

.modal_wrapper {
	background-color: $white_primary;
	border-radius: $modal_border_radius;
	padding: 50px 20px;
	padding-bottom: 30px;
	box-shadow: 10px 10px 30px rgba($color: $black_primary, $alpha: 0.16);

	@media (max-width: 575px) {
		padding: 25px 15px;
		padding-bottom: 15px;
	}

	@media (max-width: 420px) {
		//padding: 45px 5px;
		//padding-bottom: 20px;
		.heading {
			font-size: 14px;
		}
	}

	.close_icon {
		position: absolute;
		top: 30px;
		right: 30px;
		background: rgba($black_primary, 0.8);
		width: 30px;
		height: 30px;
		border-radius: 100%;
		cursor: pointer;
		text-align: center;

		@media (max-width: 575px) {
			top: 15px;
			right: 15px;
		}
		
		@include doFadeTrans();
		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			-webkit-transform: translate(-50%, -50%);
			-o-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
		}

		&:hover {
			background-color: rgba($black_primary, 1);
			transform: rotate(360deg);
		}
	}

	.popup_title {
		font-size: 24px;
		font-family: $gilroy_bold;
		margin-bottom: 10px;
		@media (max-width:575px) {
			max-width: 70%;
			margin: auto;
		}
	}

	.btn-danger {
		background-color: transparent;
		color: $black_primary;
		border-color: $black_primary;
		&:focus {
			box-shadow: none;
		}
	}
	.modal-footer {
		padding-bottom: 0;
		padding-left: 0;
		padding-right: 0;
	}

	&.dark_theme {
		background-color: $black_primary;
		color: $white_primary;
		background-image: url('/assets/images/backgrounds/Modal-BG.jpg');
		background-size: cover;
		background-position: center center;

		padding: 50px 50px;
		@media (max-width: 575px) {
			padding: 45px 20px;
			padding-bottom: 30px;
		}

		@media (max-width: 420px) {
			padding: 45px 10px;
			padding-bottom: 20px;
		}

		.close_icon {
			background-color: $white_primary;
			svg {

				path {
					stroke: $black_primary !important;
				}
			}
		}

		.page_title {
			color: $white_primary;
		}
		.form_wrapper.form_modal {
			.modal_link,
			.form-check-label {
				color: $white_primary !important;
			}
		}
		.sign_in_link {

			a {
				outline: none;
				color: $blue_primary;
			}

		}
		.forgot_password {
			text-align: center;
			a {
				color: $white_primary;
				font-size: 12px;
				text-decoration: underline;
			}
		}
	}

	.page_title {
		font-size: 30px;
		@media (max-width: 575px) {
			font-size: 26px;
		}
	}

	.sign_in_link {
		a {
			outline: none;
			color: $black_primary;
			text-decoration: none;
		}
	}

	.form_wrapper {
		padding-top: 20px;
	}
}

.modal-backdrop {
	background-color: #424242;
}
