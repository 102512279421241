.blackbook {
	.small_img_wrap {
		position: relative;
		//left: 0.75rem;
		width: 40px;
		height: 40px;
		top: calc(50% - 12.5px);
		background-color: $white_primary;
		border-radius: 100%;
		vertical-align: middle;
		display: inline-block;
		svg {
			height: auto;
			width: 100%;
		}

		.jockey-img {
			height: auto;
			width: 100%;
			padding: 5px;
			left: 0;
		}
	}
	.name_wrap {
		padding-left: 10px;
		vertical-align: middle;
		display: inline-block;
		.player_name {
			font-family: $gilroy_bold;
			font-size: 20px;
			@media (max-width:580px) {
				font-size: 18px;
			}
			@media (max-width:360px) {
				font-size: 16px;
			}
		}
		@media (max-width:360px) {
			display: inline-block !important;
			padding-top: 0 !important;
			padding-right: 0 !important;
			padding-left: 10px !important;
		}
	}
}
