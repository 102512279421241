.resources_widget {
	margin-bottom: 40px;
	.panel {
		//padding: 32px 22px;
		.resources_single {
			margin-top: 30px;
			@media (max-width: 767px) {
				margin-top: 20px;
				flex: 0 0 50%;
				max-width: 50%;
				&:nth-of-type(3) {
					display: none !important;
				}
			}

			@media (max-width: 575px) {
				flex: 0 0 100%;
				max-width: 100%;
			}
			.panel {
				padding: 30px 20px;
				padding-bottom: 90px;
				.title {
					font-size: 16px;
					font-family: $gilroy_semiBold;
					margin-bottom: 10px;
					color: $black_primary;
					.loading-text {
						max-width: 120px;
						height: 20px;
						margin-bottom: 10px;
					}
				}
				.body {
					margin-bottom: 10px;
					.loading-text {
						height: 18px;
						margin-bottom: 10px;
					}
				}

				@media (max-width: 1480px) {
					padding: 30px 15px;
					padding-bottom: 90px;
				}
			}
			.img {
				background-size: cover;
				background-position: center center;
				background-repeat: no-repeat;
				background-color: rgba($color: $black_primary, $alpha: 0.6);
				height: 130px;
				border-radius: 28px;
				width: calc(100% - 40px);
				margin: 0 auto;
				margin-top: -70px;

				@media (max-width: 1480px) {
					width: calc(100% - 30px);
				}
				@media (max-width: 575px) {
					height: 150px;
				}
			}
			@media (min-width: 1200px) and (max-width: 1480px) {
				&:nth-child(3n + 4),
				&:first-child {
					padding-right: 7.5px;
				}
				&:nth-child(3n + 3) {
					padding-left: 7.5px;
				}
				&:nth-child(3n + 2) {
					padding-left: 7.5px;
					padding-right: 7.5px;
				}
			}
		}

		@media (min-width: 1200px) and (max-width: 1480px) {
			padding: 30px 20px;
		}
	}
}
